import axios from "axios"
export class RelatorioRomaneio {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Romaneio`
  }
  imprimir(idRomaneio) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }

    return axios({
      url: this.urlServico + `/GetSDiferencaRomaneioSaidasXlsx?idRomaneio=${idRomaneio}`,
      method: "GET",
      responseType: "blob",
      ...config // important
    })
  }
  getItensNaoVendidosPorIdRomaneio(idRomaneio) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }

    return axios({
      url: this.urlServico + `/GetItensNaoVendidosPorIdRomaneio?idRomaneio=${idRomaneio}`,
      method: "GET",
      responseType: "blob",
      ...config // important
    })
  }
}
