<template>
  <v-select
    autocomplete
    label="Romaneio"
    placeholder="Selecione..."
    :rules="[() => !!codRomaneio || 'Campo obrigatório']"
    return-object
    v-model="codRomaneio"
    :items="romaneios"
    @input="setSelected"
    item-text="Nome"
    required
  ></v-select>
</template>
<script>
import { serverBus } from "../../main"
import RomaneioSelect from "../../domain/romaneio/RomaneioSelect"
import { ServicoRomaneio } from "../../servicos/servicoRomaneio"
const servicoRomaneio = new ServicoRomaneio()
export default {
  data: () => ({
    romaneios: [],
    codRomaneio: ""
  }),
  created() {
    servicoRomaneio.buscarRomaneiosSelect().then(
      res => {
        if (res.status === 200) {
          res.data.forEach(element => {
            var romaneio = new RomaneioSelect()
            romaneio.Id = element.id
            romaneio.Nome = element.dataString + " - " + element.praca + " - " + element.funcionario
            romaneio.IdFuncionario = element.idFuncionario
            romaneio.IdPraca = element.idPraca
            romaneio.Funcionario = element.funcionario

            this.romaneios.push(romaneio)
          })
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("romaneioSelected", value)
    }
  }
}
</script>
